<template>
  <div id="events" class="">
    <!-- Titolo per eventi futuri -->
    <div v-if="Object.keys(sortedEventsByYear.future).length" class="row">
      <h3 class="col-12">{{ futureTitle }}</h3>
      <div class="col-12" v-for="year in sortedYears(sortedEventsByYear.future)" :key="year">
        <h5>{{ year }}</h5>
        <hr>
        <div class="row">
          <div 
            class="col-lg-2 col-md-3 col-sm-6 col-12 mb-4" 
            v-for="event in sortedEventsByYear.future[year]" 
            :key="event.id"
          >
            <!-- Differenzia tra link interni e link esterni -->
            <template v-if="eventType === 'fair' && event.linkFair && event.linkFair.startsWith('http')">
              <a :href="event.linkFair" class="event-link" target="_blank">
                <div v-if="event.imageUrl" class="event-box text-center">
                  <img :src="event.imageUrl" alt="Event Image" />
                </div>
                <p class="fw-bold m-1 text-center">{{ event.name }}</p>
                <p class="text-center">{{ event.subTitle }}</p>
              </a>
            </template>
            <!-- Per eventi che non sono link esterni (eventType !== 'fair' o link interno) -->
            <template v-else>
              <router-link :to="getPath(event)" class="event-link" :target="eventType === 'fair' ? '_blank' : '_self'">
                <div v-if="event.imageUrl" class="event-box text-center">
                  <img :src="event.imageUrl" alt="Event Image" />
                </div>
                <p class="fw-bold m-1 text-center">{{ event.name }}</p>
                <p class="text-center">{{ event.subTitle }}</p>
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Titolo per eventi passati -->
    <div v-if="Object.keys(sortedEventsByYear.past).length" class="row ">
      <h3 class="col-12">{{ pastTitle }}</h3>
      <div class="col-12" v-for="year in sortedYears(sortedEventsByYear.past)" :key="year">
        <h5>{{ year }}</h5>
        <hr>
        <div class="row">
          <div 
            class="col-lg-2 col-md-3 col-sm-6 col-12 mb-4" 
            v-for="event in sortedEventsByYear.past[year]" 
            :key="event.id"
          >
            <!-- Differenzia tra link interni e link esterni -->
            <template v-if="eventType === 'fair' && event.linkFair && event.linkFair.startsWith('http')">
              <a :href="event.linkFair" class="event-link" target="_blank">
                <div v-if="event.imageUrl" class="event-box text-center">
                  <img :src="event.imageUrl" alt="Event Image" />
                </div>
                <p class="fw-bold m-1 text-center">{{ event.name }}</p>
                <p class="text-center">{{ event.subTitle }}</p>
              </a>
            </template>
            <!-- Per eventi che non sono link esterni (eventType !== 'fair' o link interno) -->
            <template v-else>
              <router-link :to="getPath(event)" class="event-link" :target="eventType === 'fair' ? '_blank' : '_self'">
                <div v-if="event.imageUrl" class="event-box text-center">
                  <img :src="event.imageUrl" alt="Event Image" />
                </div>
                <p class="fw-bold m-1 text-center">{{ event.name }}</p>
                <p class="text-center">{{ event.subTitle }}</p>
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { formatDate, getYearFromDate } from '@/utils';

export default {
  name: 'EventList',
  props: {
    events: {
      type: Array,
      required: true
    },
    futureTitle: {
      type: String,
      default: 'Future Events'
    },
    pastTitle: {
      type: String,
      default: 'Past Events'
    },
    eventType: String // 'exhibition', 'new' o 'fair'
  },
  computed: {
    sortedEventsByYear() {
      const currentDate = new Date();

      const futureEvents = this.events
        .map(event => ({
          ...event,
          endDate: new Date(event.endDate),
          year: getYearFromDate(event.endDate)
        }))
        .filter(event => event.endDate >= currentDate);

      const pastEvents = this.events
        .map(event => ({
          ...event,
          endDate: new Date(event.endDate),
          year: getYearFromDate(event.endDate)
        }))
        .filter(event => event.endDate < currentDate);

      return {
        future: this.groupEventsByYear(futureEvents),
        past: this.groupEventsByYear(pastEvents)
      };
    }
  },
  methods: {
    groupEventsByYear(events) {
      return events.reduce((acc, event) => {
        const year = event.year;
        if (!acc[year]) acc[year] = [];
        acc[year].push(event);
        return acc;
      }, {});
    },
    sortedYears(eventsByYear) {
      return Object.keys(eventsByYear).sort((a, b) => b - a); // Ordina dal più recente al più vecchio
    },
    getPath(event) {
      if (this.eventType === 'exhibition') {
        return `/exhibitions/${event.id}`;
      } else if (this.eventType === 'new') {
        return `/news/${event.id}`;
      } else if (this.eventType === 'fair') {
        // Assicurati che linkFair sia valido
        return event.linkFair && event.linkFair.trim() ? event.linkFair : '/default-fair'; // Usa un fallback se linkFair è mancante o vuoto
      } else {
        return '/'; // Default route or handle as needed
      }
    },
    formatDate(date) {
      return formatDate(date);
    }
  }
}
</script>

<style scoped>
.event-box {
  height: 150px; /* Altezza fissa */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.event-box img {
  height: 100%; /* Adatta l'altezza dell'immagine al contenitore */
  width: 100%; /* Mantiene l'immagine a larghezza completa */
  object-fit: cover;
}
.event-link {
  text-decoration: none;
  color: black;
}
.fw-bold {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.m-1 {
  margin: 1rem;
}
</style>
