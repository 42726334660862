<template>
  <div id="fairs" class="container">
    <EventList :events="fairsList" :futureTitle="$t('message.fairs.future')" :pastTitle="$t('message.fairs.past')" eventType="fair"/>
  </div>
</template>

<script>
import { parseDate } from '@/utils';
import EventList from '../components/EventList.vue';

export default {
  name: 'FairsView',
  components: {
    EventList
  },
  data() {
    return {
      fairsList: []
    }
  },
watch: {
    // Watch per monitorare i cambiamenti della lingua
    '$i18n.locale': 'fetchFairs'
  },
  methods: {
    async fetchFairs() {
      const language = this.$i18n.locale; // Ottieni la lingua corrente

      // Costruisci il percorso del file JSON in base alla lingua
      const filePath = `/data/fairs_${language}.json`;

      try {
        const response = await fetch(filePath); // Fetch dinamico in base alla lingua
        const data = await response.json();

        // Mappa i dati delle fiere, aggiungendo le date
        this.fairsList = data.fairs.map(fair => ({
          ...fair,
          startDate: parseDate(fair.startDate),
          endDate: parseDate(fair.endDate)
        }));
      } catch (error) {
        console.error('Error fetching fairs:', error);
      }
    }
  },
  mounted() {
    this.fetchFairs();
  }
}
</script>
