// src/lang/i18n.js
import { createI18n } from 'vue-i18n';
import it from './it';
import en from './en';

const messages = { en, it };

const i18n = createI18n({
    locale: 'it', // Lingua predefinita
    fallbackLocale: 'en',
    messages
});

export default i18n;
