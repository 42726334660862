<template>
    <div id="event-details">

        <div class="event-jumbotron parallax" :style="{ backgroundImage: `url(${events.imageUrl})` }">
          <h2 class="event-jumbotron-content container">{{ events.name }}</h2>
        </div>

        <div class="container">
            <div class="row">
                <h4 class="col-12">{{ $t('message.artists.artist.description') }} <hr> </h4>

                <div v-if="this.events.description" class="col-12">
                    <p :class="{ expanded: isExpanded }" v-html="displayedDescription"></p>
                    <p v-if="this.events.description.length > this.maxLength"  @click="toggleBio" class="text-expand">
                        {{ isExpanded ?  $t('message.artists.artist.view_less') :  $t('message.artists.artist.view_more')  }}
                    </p>
                </div>
            </div>
        </div>

    </div>
      
</template>
<script>
 export default {
    name: 'EventList',
    props: {
        events: {
            type: Array,
            required: true,      
        }, // 'artist', 'exhibition' or 'new' object
    },
    data() {
        return{
            isExpanded: false,
            maxLength: 700 // Numero massimo di caratteri da mostrare inizialmente
        }
    },
    computed: {
        displayedDescription() {
            if (this.events && this.events.description) {
                if (this.isExpanded) {
                return this.events.description;
                } else {
                return this.events.description.length > this.maxLength 
                    ? this.events.description.slice(0, this.maxLength) + '...' 
                    : this.events.description;
                }
            }
            return ''; // Restituisce una stringa vuota se event.description non è definita
        } 
    },
    methods: {
    toggleBio() {        
        this.isExpanded = !this.isExpanded;
    }
  },
}
</script>

<style scoped>


.text-expand{
  text-decoration: underline;
  cursor: pointer;
}
p {
  transition: max-height 0.3s ease;
  overflow: hidden;
}

p.expanded {
  max-height: none;
}

.event-jumbotron {
  position: relative;
  height: 300px;
  overflow: hidden;
  background-attachment: fixed; /* Effetto parallax */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; /* Impedisce la ripetizione dell'immagine */
}
/* Overlay trasparente sopra l'immagine */
.event-jumbotron::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo nero semi-trasparente */
  z-index: 1; /* Metti l'overlay sopra l'immagine ma sotto il testo */
}


/* Parallax effect */
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
}
.event-jumbotron-content {
  position: absolute;
  bottom: 0; /* Distanza dal bordo inferiore */
  z-index: 2; /* Assicurati che il testo sia sopra l'overlay */
  color: white;
  font-size: 34px;
  text-align: center;
   /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%); /* Centra perfettamente il testo */
}

</style>