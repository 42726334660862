
<template>
    <footer id="footer" class="">

        <div class="">
            <div class="footer-top container d-flex justify-content-center align-items-center flex-wrap">
                

                <div class="box d-flex justify-content-right align-items-center">

                    
                    <img class="logo mx-3" src="../assets/logo.svg" alt="">   
                    
                    <div class="text">
                        <div style="color: white"> <strong>Galleria Stefano Forni</strong></div> 
                        <div>Piazza Cavour, 2 </div>
                        <div>40124 Bologna</div>
                        <div>arte@galleriastefanoforni.com</div>
                    </div>
                </div>

                 <!-- <nav class="menu d-flex flex-wrap">
                    <a
                    v-for="link in navLinks"
                    :key="link.text"
                    href="#"
                    :class="[setActiveLink(link.href) ? 'active nav-link  p-2' : 'nav-link  p-2']"
                    @click.prevent="navigate(link.href)"
                    active-class="active"
                    >
                    {{ link.text }}
                    </a>
                </nav>  -->
               
                    <!-- <div class="info row">

                    <div class="position col-md-4">
                        <img class="icon" src="../assets/position-footer.svg" alt="">
                        <p>
                            Piazza Cavour, 2 <br/>
                            40124 - Bologna <br/>
                        </p>
                    </div>

                    <div class="contact col-md-4">
                        <img class="icon" src="../assets/phone-footer.svg" alt="">
                        <div class="number">
                            <a href="tel:+39051225679"> +39 051 225679</a>
                        </div>
                        <div class="email">
                            <a href="mailto:arte@galleriastefanoforni.com">arte@galleriastefanoforni.com</a>
                        </div>
                    </div>

                    <div class="hours col-md-4">
                        <img class="icon" src="../assets/hours-footer.svg" alt="">
                        <p class="py-2" v-html=" $t('message.about_us.info_hours')"/>
                    </div>
                </div> -->
               
            </div>

            <hr>
            <div class="footer-bottom text-center container">
                <div class="social d-flex justify-content-center mb-3">
                    <div class="facebook p-1 mx-1"> <a href="https://it-it.facebook.com/galleriastefanoforni/" target="_blank"><i class="bi bi-facebook"></i></a> </div>
                    <div class="instagram p-1 mx-1"> <a href="https://www.instagram.com/galleriastefanoforni/" target="_blank"><i class="bi bi-instagram"></i></a></div>
                    <!-- <div class="twitter p-1 mx-1"> <a href="https://www.instagram.com/galleriastefanoforni/" target="_blank"><i class="bi bi-twitter"></i></a></div> -->
                </div>
                
                <div>Copyright <span>&copy;</span> 2025 | Isabel Gogov</div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'AppHeader',
        data() {
            return {
            navLinks: [
                { href: '/', text: `${this.$t("message.footer.home")}`},
                { href: '/exhibitions', text: `${this.$t("message.footer.exhibitions")}`},
                { href: '/artists', text: `${this.$t("message.footer.artists")}`},
                { href: '/news', text: `${this.$t("message.footer.news")}`},
                { href: '/fairs', text: `${this.$t("message.footer.fairs")}`},
                { href: '/contact', text: `${this.$t("message.footer.about_us")}`}
            ]
            };
        },
        methods: {
            navigate(href) {
                // Usa Vue Router per navigare senza ricaricare la pagina
                this.$router.push(href);
            },
            setActiveLink(href) {
                // Verifica se il percorso attuale corrisponde all'URL del link
                return this.$route.path === href;
            },
        }
    }
    
</script>

<style lang="scss" scoped>

    #footer {
        background-color: #3d3d3d;
        color: #a9a9a9;
    }

    .footer-top {
        gap: 50px; /* Aggiungi spazio tra gli elementi flex */
    }

    .nav-link:hover, .active{
    text-decoration: underline; 
    color: #a9a9a9; 
    }
    a {
        color: #a9a9a9;
    }
    .social div {
        color: white;
        // border: 1px solid #ccc;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo, .logo img {
        color: #ffffff;
        height: 120px;
    }
    .icon {
        width: 30px;
    }
    i {
        color: white;
        font-size: 20px;
    }

    .facebook  {
        background: #316ff6;
    }
    .instagram {
        background: #c1558b;
    }
    .twitter {
        background: #1c9cea;
    }
    
</style>

