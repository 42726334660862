export default {

    message:{
        hello: "Hello",
        header: {
            home: "Home",
            exhibitions: "Exhibitions",
            artists: "Artists",
            news: "News",
            fairs: "Fairs",
            about_us: "Contact us",
        },
        home: {
            default_title: "Open Wednesday to Saturday",
            default_subtitle: "We look forward to seeing you!",
            new_arrives: "New arrive",
            text_hour: "Hours: Wednesday to Saturday | 10.00-12.30 and 16.00-19.30"
        },
        exhibitions: {
            future: "Current / Future exhibitions",
            past: "Past exhibitions"
        },
        artists: {
            title: "Artists",
            all: "All",
            painters: "Painter",
            sculptors: "Sculptor",
            photographer: "Photograph",
            artist: {
                description: "Description",
                artworks: "Artwork",
                contact_us: "Contact us",
                view_more: "view more",
                view_less: "view less",
                info_button: "Request information",
                contact: {
                    subject: "Information request for {artistName}'s {artworkName}",
                    body: {
                        greeting: "Hello,",
                        body: "I am interested in the {artistName}'s artwork' titled \"{artworkName}\". Please provide me more information.",
                        thank_you: "Thank you."
                    }
                }
            }
        },
        fairs: {
            future: "Future fairs",
            past: "Past fairs"
        },
        about_us: {
            title: "Contact us",
            info: "The Stefano Forni Gallery is located in the heart of the historic center of Bologna, in Piazza Cavour, 2. Founded by Stefano Forni in 1996 and today directed by his wife Silvia Forni, the gallery is a point of reference for contemporary art, presenting a rich program of personal and collective exhibitions, with particular attention to painting, sculpture, graphics and photography. <br><br> We represent nationally and internationally renowned artists, including Luciano Ventrone, Armodio, Piero Guccione, Gianfranco Ferroni, Carlo Mattioli and many others. Every year the Gallery participates in prestigious national and international art fairs, such as Art Miami, Arte Fiera, Art Padova, Art Karlsruhe and Art Madrid. <br><br> We will be happy to welcome you to the gallery and let you discover our exhibitions that will immerse you in the beauty of modern and contemporary art. Furthermore, follow us on Instagram and Facebook to stay up to date on the latest news and upcoming events.",
            location: "Location",
            contact_us: "Contact Us",
            hours: "Hours",
            info_hours: "from Wednesday to Saturday <br> 10.00-12.30 and 16.00-19.30"
        },
        mailchimp: {
            title: "Subscribe to the Newsletter",
            required: "indicates required",
            first_name: "First Name",
            last_name: "Last Name",
            email: "Email Address",
            privacy_title: "I accept the terms and privacy",
            privacy_text: "I consent to the processing of data as described in the",
            subscribe: "Subscribe",
        },
        footer: {
            home: "HOME",
            exhibitions: "EXHIBITIONS",
            artists: "ARTISTS",
            news: "NEWS",
            fairs: "FAIRS",
            about_us: "ABOUT US",
        }
    }
}