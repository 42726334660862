<template>
  <div id="home">
    <HomeJumbotron></HomeJumbotron>

    <!-- Mostra lo spinner quando loading è true -->
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Mostra le opere solo se ci sono -->
    <!-- <div v-if="newArtworks.length" class="container">
      <ArtworksList :artworks="newArtworks" :title="$t('message.home.new_arrives')" />
    </div> -->
  </div>
</template>

<script>
import HomeJumbotron from '../components/HomeJumbotron.vue';
// import ArtworksList from '../components/ArtworksList.vue';

export default {
  name: 'HomeView',
  components: {
    HomeJumbotron,
    // ArtworksList
  },
  data() {
    return {
      artworks: [],
      loading: true,  // Aggiungi la variabile loading
    };
  },
  computed: {
    // Filtra solo le nuove opere arrivate
    newArtworks() {
      return this.artworks.filter(artwork => artwork.newComer === true);
    },
  },
  async mounted() {
    try {
      // Fetch delle artworks
      const responseArtworks = await fetch('/data/artworks.json');
      if (!responseArtworks.ok) {
        throw new Error('Network response was not ok');
      }
      const dataArtworks = await responseArtworks.json();
      this.artworks = dataArtworks.artworks;

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      this.loading = false;  // Imposta loading su false quando il fetch è completato
    }
  }
}
</script>
