<template>
    <div>
        Store
    </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {

  }
}
</script>