<template>
  <header class="fixed-top d-flex ">
    <nav class="w-100 navbar navbar-expand-lg bg-body-tertiary">
      <div class="w-100 d-flex justify-content-between container-fluid">
        <!-- Mobile Menu Toggle Button -->
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Brand Logo -->
        <a class="navbar-brand order-first order-lg-last" href="/" @click.prevent="navigate('/')">
          <img src="../assets/logo.jpg" alt="logo galleria stefano forni">
        </a>

        <!-- Offcanvas Menu -->
        <div class="offcanvas offcanvas-end py-5" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <button type="button" class="btn-close ms-auto me-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <!-- Navigation Links -->
            <div class="navbar-nav justify-content-lg-start flex-grow-1 w-100 text-center">
              <div class="d-lg-flex ms-lg-3">
                <a
                  v-for="link in navLinks"
                  :key="link.text"
                  href="#"
                  :class="[setActiveLink(link.href) ? 'active nav-link' : 'nav-link']"
                  @click.prevent="navigate(link.href)"
                  data-bs-dismiss="offcanvas"
                  exact-active-class="active"
                  active-class="active"

                >
                  {{ link.text }}
                </a>
              </div>

              <!-- ITA | ENG -->
              <div class="d-flex align-items-center justify-content-center mt-lg-0 mt-3 p-lg-2 order-lg-first order-last">
                <a class="nav-link p-0" @click.prevent="changeLanguage('it')" data-bs-dismiss="offcanvas" :class="{ 'active': isCurrentLanguage('it') }"> <span style="cursor: pointer;">ITA</span></a>
                <span class="mx-1">|</span>
                <a class="nav-link p-0" @click.prevent="changeLanguage('en')" data-bs-dismiss="offcanvas" :class="{ 'active': isCurrentLanguage('en') }"><span style="cursor: pointer;">ENG</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>


<script>
export default {
  name: 'AppHeader',

computed: {
  navLinks() {
    return [
      { href: '/', text: this.$t("message.header.home") },
      { href: '/exhibitions', text: this.$t("message.header.exhibitions") },
      { href: '/artists', text: this.$t("message.header.artists") },
      { href: '/fairs', text: this.$t("message.header.fairs") },
      { href: '/contact', text: this.$t("message.header.about_us") }
    ];
  }
},
  methods: {
    navigate(href) {
      // Usa Vue Router per navigare senza ricaricare la pagina
      this.$router.push(href);
    },
    setActiveLink(href) {
      // Verifica se il percorso attuale corrisponde all'URL del link
      return this.$route.path === href;
    },
    changeLanguage(lang) {
      // Cambia la lingua usando vue-i18n
      this.$i18n.locale = lang; // Modifica la lingua corrente
      localStorage.setItem('lang', lang); // Salva la lingua nel localStorage
    },
    isCurrentLanguage(lang) {
      // Verifica se la lingua corrente è quella fornita
      // console.log(`Controllando lingua corrente: ${lang}, ${this.$i18n.locale}`);
      return this.$i18n.locale == lang;
    },
  }
};
</script>

<style scoped>
header {
  background-color: white;
  font-size: 1.2em;
  height: 130px;
}

img {
  max-height: 80px;
}

.nav-link, nav {
  margin: 1em !important;
}

.nav-link:hover, .active{
  text-decoration: underline;  
}

button {
  border: none !important;
}

.offcanvas-header {
  position: relative;
}

.btn-close {
  margin: 1em;
}


</style>
