import { createWebHistory, createRouter } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import ExhibitionsView from '../views/ExhibitionsView.vue';
import ArtistsView from '../views/ArtistsView.vue';
import FairsView from '../views/FairsView.vue';
import AboutView from '../views/AboutView.vue';
import StoreView from '../views/StoreView.vue';
import PageNotFound from '../views/PageNotFind.vue'
import ArtistDetails from '../views/ArtistDetails.vue';
import ExhibitionDetails from '../views/ExhibitionDetails.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import NewsView from '../views/NewsView.vue';
import NewDetails from '../views/NewDetails.vue';

const routes = [
    { path: '/', component: HomeView },
    { path: '/exhibitions', component: ExhibitionsView },
    {
        path: '/exhibitions/:id',
        name: 'ExhibitionDetail',
        component: ExhibitionDetails,
        props: true
    },
    { path: '/news', component: NewsView },
    { path: '/fairs', component: FairsView },
    {
        path: '/news/:id',
        name: 'NewDetail',
        component: NewDetails,
        props: true
    },
    {
        path: '/artists', component: ArtistsView
    },
    {
        path: '/artists/:id',
        component: ArtistDetails,
        props: true // Passa id come prop al componente
    },
    { path: '/contact', component: AboutView },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/store', component: StoreView },
    {
        path: '/:catchAll(.*)*',
        component: PageNotFound,
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

export default router