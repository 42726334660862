<template>
  <div id="exhibition-detail">
    <!-- Mostra lo spinner quando loading è true -->
    <div v-if="loading" class="d-flex justify-content-center align-items-center min-vh-100">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Mostra i dettagli dell'esposizione quando exhibition è disponibile -->
    <div v-if="exhibition">
      <EventDetails :events="exhibition"></EventDetails>
    </div>

  </div>
</template>

<script>
import EventDetails from '../components/EventDetails.vue';
import { parseDate } from '@/utils';

export default {
  name: 'ExhibitionDetail',
  components: {
    EventDetails,
  },
  data() {
    return {
      exhibition: null, // Modifica la variabile per memorizzare il dettaglio dell'esposizione
      exhibitions: [],   // Memorizza tutte le esposizioni
      loading: true,     // Variabile per tracciare il caricamento
    };
  },
  watch: {
    // Monitora i cambiamenti della lingua
    '$i18n.locale': 'fetchExhibitions',
  },
  methods: {
    async fetchExhibitions() {
      this.loading = true; // Imposta loading a true quando inizia il fetch
      try {
        // Ottieni la lingua corrente
        const language = this.$i18n.locale;
        
        // Definisci il percorso del file in base alla lingua
        const filePath = `/data/exhibitions_${language}.json`; 
        
        const response = await fetch(filePath);
        const data = await response.json();
        
        // Mappa tutte le mostre
        this.exhibitions = data.exhibitions.map(exhibition => ({
          ...exhibition,
          startDate: parseDate(exhibition.startDate),
          endDate: parseDate(exhibition.endDate),
        }));

        // Carica l'esposizione specifica in base all'ID
        const exhibitionId = parseInt(this.$route.params.id, 10); // Ottieni l'ID dalla route
        this.exhibition = this.exhibitions.find(exhibition => exhibition.id === exhibitionId);

        if (!this.exhibition) {
          throw new Error(`Exhibition with id ${exhibitionId} not found`);
        }
      } catch (error) {
        console.error('Error fetching exhibitions:', error);
      } finally {
        this.loading = false;  // Imposta loading a false quando il fetch è completato
      }
    },
  },
  mounted() {
    this.fetchExhibitions();
  },
};
</script>
