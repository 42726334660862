<!-- src/App.vue -->
<template>
  <div id="app">
    <AppHeader />
    <router-view></router-view>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
   mounted() {
    // Recupera la lingua salvata nel localStorage, se presente
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      this.$i18n.locale = savedLang;  // Imposta la lingua
    }
  }

}
</script>

<style>

</style>
