<template>
  <div id="artists" class="container">
    <div class="row">
      <h3 class="col-12">{{ $t('message.artists.title') }}</h3> <!-- Titolo basato sulla lingua -->
      <hr>
      <div class="col-auto ms-auto">
        <div class="filters d-flex pb-3">
          <h5 class="filters-item me-4" @click="filterByType('')">
            <span :class="{ active: filterType === '' }">{{ $t('message.artists.all') }}</span>
          </h5>
          <h5 class="filters-item me-4" @click="filterByType('painter')">
            <span :class="{ active: filterType === 'painter' }">{{ $t('message.artists.painters') }}</span>
          </h5>
          <h5 class="filters-item me-4" @click="filterByType('sculptor')">
            <span :class="{ active: filterType === 'sculptor' }">{{ $t('message.artists.sculptors') }}</span>
          </h5>
          <h5 class="filters-item me-4" @click="filterByType('photographer')">
            <span :class="{ active: filterType === 'photographer' }">{{ $t('message.artists.photographer') }}</span>
          </h5>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div 
        class="col-lg-2 col-md-3 col-sm-6 col-12 mb-4" 
        v-for="artist in filteredArtists" 
        :key="artist.id"
      >
        <router-link :to="{ path: `/artists/${artist.id}` }">
          <div  v-if="artist.imageUrl" class="artist-box text-center">
            <img :src="artist.imageUrl" alt=""> 
          </div>

          <div v-else class="placeholder" style="height: 150px; display: flex; align-items: center; justify-content: center; color: gray;">
            No Image Available
          </div>
          <p class="fw-bold m-1 text-center"> {{ artist.name }} {{ artist.lastName }} </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistsView',
  components: {

  },
  data() {
    return {
      artists: [],
      filterType: '' 
    }
  },
  computed: {
    filteredArtists() {
      if (this.filterType) {
        return this.artists.filter(artist => artist.type === this.filterType);
      }
      return this.artists;
    
    },
  },
  methods: {
    filterByType(type) {
      this.filterType = type;
    },
    async fetchArtists() {
      const language = this.$i18n.locale; // Ottieni la lingua corrente
      const filePath = `/data/artists_${language}.json`; // Percorso file in base alla lingua
      
      try {
        const response = await fetch(filePath);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        this.artists = data.artists;
        
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }
  },
  watch: {
    // Monitora i cambiamenti della lingua e ricarica i dati degli artisti
    '$i18n.locale': 'fetchArtists'
  },
  async mounted() {
    await this.fetchArtists(); // Carica gli artisti iniziali
  }
}
</script>
<style lang="scss" scoped>
  a{
    color: black;
    text-decoration: none;
  }
  .filters-item{
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);
    transition: color 0.3s ease; 
  }
  .filters-item:hover, .active  {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.9);
    // transform: scale(1.1);
  }
  .active{
    font-weight: 500;

  }
  .artist-box {
  // border: 1px solid black;
  height: 150px; /* Altezza fissa */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.artist-box img {
  height: 100%; /* Adatta l'altezza dell'immagine al contenitore */
    width: 100%; /* Mantiene l'immagine a larghezza completa */
    object-fit: cover; 
}
</style>