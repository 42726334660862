<template>
    <div id="about" class="">


      <div class="about-jumbotron parallax">
        <h2 class="about-jumbotron-content ">{{$t('message.about_us.title')}}</h2>
      </div>

      <div class="container">
        <div class="row justify-content-center">

          <!-- long text -->
          <!-- <div class="col-12 my-3" v-html=" $t('message.about_us.info')"></div> -->

          <!-- icons -->
            <div class=" col-md-4 my-3 text-center">
          <div class="card p-2">
              <img class="icon mb-2" src="../assets/position.svg" alt="">
              <h5>{{ $t('message.about_us.location') }}</h5>
              <div class="text"> Piazza Cavour, 2 <br> 40124 - Bologna</div>
            </div>
          </div>

          <div class=" col-md-4 my-3 text-center">
          <div class="card p-2">
            <img class="icon mb-2" src="../assets/phone.svg" alt="">
            <h5>{{ $t('message.about_us.contact_us') }}</h5>
            <div class="text"> Tel./Fax +39.051.225679 <br> arte@galleriastefanoforni.com</div>
          </div>
          </div>

          <div class=" col-md-4 my-3 text-center">
          <div class="card p-2">
            <img class="icon mb-2" src="../assets/hours.svg" alt="">
            <h5>{{ $t('message.about_us.hours') }}</h5>
            <div class="text" v-html=" $t('message.about_us.info_hours')"></div>
          </div>
          </div>

        </div>

        <div class="row">
          <MaichimpForm></MaichimpForm>
        </div>

      <!-- <div class=" row justify-content-center">
        <div class="maps col-lg-6 col-12 text-center">
          google maps
        </div>
      </div> -->

    </div>

</div>

    
</template>

<script>
import MaichimpForm from '@/components/MaichimpForm.vue'
export default {
  name: 'ContactView',
  components: {
    MaichimpForm

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

.about-jumbotron {
  background-image: url("../assets/galleria-stefano-forni.jpg");
  position: relative;
  height: 300px;
  overflow: hidden;
  background-attachment: fixed; /* Effetto parallax */
  background-position: 60% 20%;
  background-size: cover;
  background-repeat: no-repeat; /* Impedisce la ripetizione dell'immagine */
}
/* Overlay trasparente sopra l'immagine */
.about-jumbotron::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo nero semi-trasparente */
  z-index: 1; /* Metti l'overlay sopra l'immagine ma sotto il testo */
}


/* Parallax effect */
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
}
.about-jumbotron-content {
  position: absolute;
  bottom: 0; /* Distanza dal bordo inferiore */
  z-index: 2; /* Assicurati che il testo sia sopra l'overlay */
  color: white;
  font-size: 34px;
  text-align: center;
   /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%); /* Centra perfettamente il testo */
}


// .about-container {
//   height: 500px;
//   overflow: hidden;
// }
// .about-container img {
//     height: 100%; /* Adatta l'altezza dell'immagine al contenitore */
//     width: 100%; /* Mantiene l'immagine a larghezza completa */
//     object-fit: cover; /* Mantiene le proporzioni dell'immagine e la adatta al contenitore */
// }

h5{
  color: #800000;
}
.icon{
  width: 40px;
  margin: auto;
}
.text {
  color: #4e4e4e;
}
.maps {
  border: 1px solid;
  height: 300px;
}
</style>