export function parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
}

export function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono zero-based, quindi aggiungi 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}


export function parseDateToUsFormat(dateString) {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(`${month}/${day}/${year}`);
}

// Funzione per ottenere solo l'anno da una stringa "dd/mm/yyyy"
export function getYearFromDate(date) {
    return date.getFullYear(); // Restituisci direttamente l'anno se è un Date
}
