<template>
    <div id="page-not-find">
        <div>
            <h1>404 - Page Not Found</h1>
            <p>This page no longer exists or was moved to another location.</p>
        </div>
    </div>
  </template>

<script>
export default {
  name: 'PageNotFind',
  components: {

  }
}
</script>

<style scoped>
    #page-not-find{
        width: 100%;
        height: calc(100vh - 130px); /* Sottrai l'altezza dell'header */
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>