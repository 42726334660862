<template>
    <div id="newItem-detail">
      <!-- Mostra lo spinner quando loading è true -->
      <div v-if="loading" class="d-flex justify-content-center align-items-center min-vh-100">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="newItem">
       <EventDetails :events="newItem"></EventDetails>
      </div>
    </div>
  </template>
  
  <script>
  import EventDetails from '../components/EventDetails.vue';
    import { parseDate } from '@/utils';
  
  export default {
    name: 'NewDetail',
    components: {
        EventDetails,
    },
    data() {
      return {
        newItem: null,
        news: [], // Aggiungi un array per memorizzare tutte le novita'
        loading: true,     // Variabile per tracciare il caricamento
      };
    },
    methods: {
      async fetchNews() {
        this.loading = true;
        try {
          const response = await fetch('/data/news.json');
          const data = await response.json();
          this.news = data.news.map(newItem => ({
            ...newItem,
            startDate: parseDate(newItem.startDate),
            endDate: parseDate(newItem.endDate)
          }));
          this.setNew();
        } catch (error) {
          console.error('Error fetching news:', error);
        } finally {
          this.loading = false;  // Imposta loading a false quando il fetch è completato
      }
      },
      setNew() {
        const newItemId = parseInt(this.$route.params.id, 10);
        this.newItem = this.news.find(newItem => newItem.id === newItemId);
      }
    },
    mounted() {
      this.fetchNews();
    }
  }
  </script>
  