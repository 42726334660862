<template>
  <div id="exhibitions" class="container">
    <EventList :events="exhibitionsList" :futureTitle="$t('message.exhibitions.future')" :pastTitle="$t('message.exhibitions.past')" eventType="exhibition"/>
  </div>
</template>

<script>
import { parseDate } from '@/utils';
import EventList from '../components/EventList.vue';

export default {
  name: 'ExhibitionsView',
  components: {
    EventList
  },
  data() {
    return {
      exhibitionsList: []
    };
  },
 watch: {
    // Monitora i cambiamenti della lingua
    '$i18n.locale': 'fetchExhibitions'
  },
  methods: {
    async fetchExhibitions() {
      const language = this.$i18n.locale; // Ottieni la lingua corrente
      const filePath = `/data/exhibitions_${language}.json`; // Definisci il percorso del file in base alla lingua
      try {
        const response = await fetch(filePath);
        const data = await response.json();
        this.exhibitionsList = data.exhibitions.map(exhibition => ({
          ...exhibition,
          startDate: parseDate(exhibition.startDate),
          endDate: parseDate(exhibition.endDate)
        }));
      } catch (error) {
        console.error('Error fetching exhibitions:', error);
      }
    }
  },
  mounted() {
    this.fetchExhibitions();
  }
}
</script>
