<template>
  <div id="artist-dettails">
    <!-- Mostra lo spinner quando loading è true -->
    <div v-if="loading" class="d-flex justify-content-center align-items-center min-vh-100">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="artist">
      <EventDetails :events="artist"></EventDetails>
      <div v-if="artistArtworks.length" class="container">
        <!-- Include il componente ArtworksList -->
        <ArtworksList :artworks="artistArtworks" :title=" $t('message.artists.artist.artworks')" />
      </div>
      <div v-else class="text-center">
        <a class="btn btn-outline-dark my-2" :href="contactLink(artist.name)">
            {{ $t('message.artists.artist.info_button') }}
            
        </a>
      </div>
    </div>
  
  </div>

</template>

<script>
import ArtworksList from '../components/ArtworksList.vue';
import EventDetails from '../components/EventDetails.vue';
export default {
  name: 'DettagliArtista',
  components: {
    ArtworksList,
    EventDetails,
  },
  data() {
    return {
      artist: {},
      artworks: [],
      loading: true,     // Variabile per tracciare il caricamento
    };
  },
  computed: {
    artistArtworks() {
      // Filtra le opere d'arte per l'artista corrente
      return this.artworks.filter(artwork => artwork.idArtist === this.artist.id);
    },
  },
methods: {
    contactLink(artistname) {
      const subject = encodeURIComponent(`${artistname}`);
      return `mailto:arte@galleriastefanoforni.com?subject=${subject}&body=`;
    },
    async fetchArtistData() {
      this.loading = true; // Imposta loading a true quando inizia il fetch
      const language = this.$i18n.locale; // Ottieni la lingua corrente
      const artistFilePath = `/data/artists_${language}.json`; // Percorso file artisti in base alla lingua
      //const artworksFilePath = `/data/artworks_${language}.json`; // Percorso file opere in base alla lingua
      const artworksFilePath = `/data/artworks.json`;
      try {
        // Carica i dati dell'artista
        const responseArtist = await fetch(artistFilePath);
        if (!responseArtist.ok) {
          throw new Error('Network response was not ok');
        }
        const dataArtist = await responseArtist.json();

        const artistId = parseInt(this.$route.params.id, 10); // Ottieni l'ID dall'URL della route
        this.artist = dataArtist.artists.find(artist => artist.id === artistId);

        if (!this.artist) {
          throw new Error(`Artist with id ${artistId} not found`);
        }

        // Carica le opere dell'artista
        const responseArtworks = await fetch(artworksFilePath);
        if (!responseArtworks.ok) {
          throw new Error('Network response was not ok');
        }
        const dataArtworks = await responseArtworks.json();
        this.artworks = dataArtworks.artworks;

      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        this.loading = false;  // Imposta loading a false quando il fetch è completato
      }
    }
  },
  watch: {
    // Monitora i cambiamenti della lingua e ricarica i dati
    '$i18n.locale': 'fetchArtistData',
    // Monitora anche il cambio dell'ID artista nella URL, per ricaricare i dati
    //'$route.params.id': 'fetchArtistData'
  },
  async mounted() {
    await this.fetchArtistData(); // Carica i dati iniziali dell'artista e delle opere
  }
}
</script>