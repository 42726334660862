<template>
  <div id="mc_embed_shell">
    <!-- Include il CSS di Mailchimp -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
    <div id="mc_embed_signup">
      <form 
        action="https://galleriastefanoforni.us11.list-manage.com/subscribe/post?u=462d3cf7b91acb21f99257b40&amp;id=96fb41596d&amp;f_id=00f0c7e3f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        @submit="handleSubmit"
      >
        <div id="mc_embed_signup_scroll">
          <h2> {{$t('message.mailchimp.title')}} </h2>
          <div class="indicates-required">
            <span class="asterisk">*</span>  {{$t('message.mailchimp.required')}}
          </div>

          <!-- Nome -->
          <div class="mc-field-group">
            <label for="mce-FNAME">{{$t('message.mailchimp.first_name')}} <span class="asterisk">*</span></label>
            <input type="text" name="FNAME" class="text" id="mce-FNAME" value="" required />
          </div>

          <!-- Cognome -->
          <div class="mc-field-group">
            <label for="mce-LNAME">{{$t('message.mailchimp.last_name')}} <span class="asterisk">*</span></label>
            <input type="text" name="LNAME" class="text" id="mce-LNAME" value="" required />
          </div>

          <!-- Email -->
          <div class="mc-field-group">
            <label for="mce-EMAIL">{{$t('message.mailchimp.email')}} <span class="asterisk">*</span></label>
            <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" value="" required />
          </div>

          <!-- Termini e Privacy -->
          <div class="mc-field-group input-group">
            <strong>{{$t('message.mailchimp.privacy_title')}} <span class="asterisk">*</span></strong>
            <ul>
              <li>
                <input type="radio" name="MMERGE9" id="mce-MMERGE90" value="Acconsento al trattamento dei dati come descritto nella <a href='/privacy-policy'>Privacy Policy</a>" required />
                <label for="mce-MMERGE90"> {{$t('message.mailchimp.privacy_text')}} <a class="privacy-link" @click="navigateToPrivacy">Privacy Policy</a></label>
              </li>
            </ul>
          </div>

          <!-- Risposte al form -->
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display: none;"></div>
            <div class="response" id="mce-success-response" style="display: none;"></div>
          </div>

          <!-- Prevent Spam -->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input type="text" name="b_462d3cf7b91acb21f99257b40_96fb41596d" tabindex="-1" value="" />
          </div>

          <!-- Bottone di invio -->
          <div class="clear">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn btn-dark" :value="$t('message.mailchimp.subscribe')">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MailchimpForm',
  methods: {
    handleSubmit(event) {
      // Se vuoi fare un controllo custom o validazione prima di inviare il form:
      const form = event.target;

      // Preveniamo l'invio del modulo per fare una validazione custom se necessario
      event.preventDefault();

      // Qui possiamo fare delle azioni di validazione (se necessario)
      // In questo esempio, inviamo direttamente il form di Mailchimp
      form.submit();
    },
    navigateToPrivacy() {
      this.$router.push('/privacy-policy');
    }
  },
};
</script>

<style scoped>
#mc_embed_signup {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font: 14px Helvetica, Arial, sans-serif;
}

#mc_embed_signup input,
#mc_embed_signup button {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

#mc_embed_signup .button {
  background-color: #0073e6;
  color: white;
  cursor: pointer;
}

#mc_embed_signup .button:hover {
  background-color: #005bb5;
}
#mc_embed_signup .privacy-link{
    color: black;
}
</style>
