<template>
    <div id="news" class="container">
      <EventList :events="newsList" futureTitle="Future News" pastTitle="Past News" eventType="new"/>
    </div>
  </template>
  
  <script>
  import { parseDate } from '@/utils';
  import EventList from '../components/EventList.vue';
  
  export default {
    name: 'newView',
    components: {
      EventList
    },
    data() {
      return {
        newsList: []
      }
    },
    watch: {
      // Monitora i cambiamenti della lingua
      '$i18n.locale': 'fetchNews'
    },
    methods: {
      async fetchNews() {
        const language = this.$i18n.locale; // Ottieni la lingua corrente
        const filePath = `/data/news_${language}.json`; // Definisci il percorso del file in base alla lingua
        try {
          const response = await fetch(filePath);
          const data = await response.json();
          
          this.newsList = data.news.map(newItem => { 

            return {
                ...newItem, 
                startDate: parseDate(newItem.startDate), 
                endDate: parseDate(newItem.endDate)
            };
          });
        } catch (error) {
          console.error('Error fetching news:', error);
        }
      }
    },
    mounted() {
      this.fetchNews();
    }
  }
  </script>
  