import { createApp } from 'vue'
import App from './App.vue'
import router from './router/routes'
import i18n from './lang/i18n';

// Importa Splide e Vue-Splide
import { Splide, SplideSlide } from '@splidejs/vue-splide';

// Importa il CSS di Splide
import '@splidejs/splide/dist/css/splide.min.css';

// Importazione del CSS di Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

// Importazione di Bootstrap JS (con incluso Popper.js)
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css'

// Importazione dei tuoi stili personalizzati
import '@/styles/main.scss'

// Crea l'app Vue
const app = createApp(App)

// Registrazione globale di Splide e SplideSlide
app.component('SplideCarousel', Splide);
app.component('SplideSlide', SplideSlide);

app.use(router)
app.use(i18n)
// Monta l'app
app.mount('#app')
