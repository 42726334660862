<template>
  <div id="jumbotron" class="jumbotron">
    <div v-if="loading" class="jumbotron-image jumbotron-default">
        <!-- Puoi aggiungere un'immagine di default qui come sfondo -->
      </div>
    <Transition v-else name="fade" mode="out-in">
      <img  :src="getImageUrl"  alt="Jumbotron Image" class="jumbotron-image" :key="index">
    </Transition>
    <div class="jumbotron-content container">
      <h2 :key="index">{{ getTitle }} - {{ getSubTitle }}</h2>
    </div>
  </div>
</template>

<script>
import { parseDateToUsFormat } from '@/utils';

export default {
  name: 'HomeJumbotron',
  data() {
    return {
      index: 0,
      currentExhibitions: [],
      defaultImage: require('@/assets/galleria-stefano-forni.jpg'),
      loading: true,
    };
  },
  computed: {
    getImageUrl() {
      // Se non ci sono mostre, usa l'immagine di default
      return this.currentExhibitions.length > 0 
        ? this.currentExhibitions[this.index].imageUrl 
        : this.defaultImage;
    },
    getTitle() {
      return this.currentExhibitions.length > 0 
        ? this.currentExhibitions[this.index].name 
        : this.$t("message.home.default_title");  // Ottieni il titolo di default da i18n
    },
    getSubTitle() {
      return this.currentExhibitions.length > 0 
        ? this.currentExhibitions[this.index].subTitle 
        : this.$t("message.home.default_subtitle");  // Ottieni il sottotitolo di default da i18n
    }
  },
  methods: {
    changeJumbotron() {
      this.index++;
      if (this.index >= this.currentExhibitions.length) {
        this.index = 0;
      }
    },
    async fetchExhibitions() {
      this.loading = true; // Inizia il caricamento
      const language = this.$i18n.locale;  // Ottieni la lingua corrente
      const filePath = `/data/exhibitions_${language}.json`;  // Aggiungi il suffisso della lingua

      try {
        const response = await fetch(filePath);
        const data = await response.json();
        const today = new Date();

        // Filtra le mostre correnti
        this.currentExhibitions = data.exhibitions
          .map(exhibition => ({
            ...exhibition,
            startDate: parseDateToUsFormat(exhibition.startDate),
            endDate: parseDateToUsFormat(exhibition.endDate)
          }))
          .filter(exhibition => exhibition.endDate >= today);
      } catch (error) {
        console.error('Error fetching exhibitions:', error);
      } finally {
        this.loading = false;  // Impostato a false sia in caso di successo che di errore
      }
    }
  },
  watch: {
    // Monitora i cambiamenti della lingua
    '$i18n.locale': 'fetchExhibitions'
  },
  mounted() {
    this.fetchExhibitions();
    setInterval(() => {
      this.changeJumbotron();
    }, 5000);
  }
}
</script>

<style scoped>
.jumbotron {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: start;
  align-items: end;
  background-color: #333;
}

.jumbotron-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center top;
  filter: brightness(60%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.jumbotron-content {
  padding: 10px;
  position: absolute;
  text-align: start;
  color: white;
  z-index: 1;
}

.jumbotron-content h2 {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 30px;
  /* margin: 0; */
}
</style>
