<template>
  <div id="artworks-list">
    <!-- Usa il nome del componente modificato SplideCarousel -->
    <SplideCarousel :options="splideOptions">
      <SplideSlide v-for="(artwork, index) in artworks" :key="index">
        <h4 class="text-center">{{ title }} <span>{{ index + 1 + '/' + artworks.length }} </span></h4>
        <div class="carousel-image-container" >
          <img :src="artwork.imageUrl" class="d-block" :alt="artwork.name"  @click="showImage(artwork, index)">
        </div>
        <div class="text-center carousel-info my-2">
          <div>
            <span>{{ index + 1 + '. ' }} </span>
            <span>{{ artwork.name + ' ' }}</span>
            <span> {{ artwork.size + ' ' }}</span>
            <span>{{ artwork.tecnique }}</span>
          </div>
          <a class="btn btn-outline-dark my-2" :href="contactLink(artwork, index)">
            {{ $t('message.artists.artist.contact_us') }}
          </a>
        </div>
      </SplideSlide>
    </SplideCarousel>

     <!-- Overlay per visualizzare l'immagine a schermo intero con descrizione -->
    <div v-if="fullscreenImage" class="overlay" @click="closeImage">
        <div class="fullscreen-container">
          <!-- Bottone "X" per chiudere l'immagine -->
        <button class="close-btn" @click.stop="closeImage">&times;</button>
          <img :src="fullscreenImage.imageUrl" class="fullscreen-image" alt="Full screen image">
          <div v-if="fullscreenImage.description" class="image-description ">
            <p>{{ fullscreenImage.description }}</p>
          </div>
        </div>
    </div>
  </div>
</template>



<script>
export default {
  name: 'ArtworksList',
  components: {

  },
  props: ['artworks', 'title'],
  data() {
    return {
      fullscreenImage: null,
      splideOptions: {
        type: 'slide',
        perPage: 1,
        focus: 'center',
        gap: '1rem',
        pagination: false,
        arrows: true
      }
    };
  },
  methods: {
     showImage(artwork, index) {
      const description = `${index + 1}. ${artwork.name} ${artwork.size} ${artwork.tecnique}`;
      this.fullscreenImage = {
        imageUrl: artwork.imageUrl,
        description: description // Aggiungi la descrizione
      };
    },
    closeImage() {
      this.fullscreenImage = null;
    },
    contactLink(artwork, i) {
      let artistname = artwork.artistName;
      let artworkName = `${i + 1}. ${artwork.name}`;

      // Usa i18n per ottenere le traduzioni
      const subject = encodeURIComponent(this.$t('message.artists.artist.contact.subject', { artworkName: artworkName, artistName: artistname }));
      const greeting = encodeURIComponent(this.$t('message.artists.artist.contact.body.greeting'));
      const bodyText = encodeURIComponent(this.$t('message.artists.artist.contact.body.body', { artworkName: artworkName, artistName: artistname }));
      const thankYou = encodeURIComponent(this.$t('message.artists.artist.contact.body.thank_you'));

      return `mailto:arte@galleriastefanoforni.com?subject=${subject}&body=${greeting}%0A%0A${bodyText}%0A%0A${thankYou}`;
    }
  },
};
</script>

<style scoped>
.carousel-image-container {
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image-container img {
  width: auto;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullscreen-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}
.image-description {
  margin-top: 20px;
  color: white;
  font-size: 1.1rem;
}
/* Posiziona il bottone "X" in alto a destra */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10001;
}

.close-btn:hover {
  color: gray;
}
</style>
