export default {
    message: {
        hello: "Ciao",
        header: {
            home: "Home",
            exhibitions: "Mostre",
            artists: "Artisti",
            news: "Novità",
            fairs: "Fiere",
            about_us: "Contattaci",
        },
        home: {
            default_title: "Siamo aperti dal Mercoledì al Sabato",
            default_subtitle: "Vi aspettiamo!",
            new_arrives: "Nuovo arrivo",
            text_hour: "Orario: dal Mercoledì al Sabato | 10.00-12.30 e 16.00-19.30"
        },
        exhibitions: {
            future: "Mostre in corso / future",
            past: "Mostre passate"
        },
        artists: {
            title: "Artisti",
            all: "tutti",
            painters: "pittori",
            sculptors: "scultori",
            photographer: "fotografi",
            artist: {
                description: "Descrizione",
                artworks: "Opera",
                contact_us: "Contattaci",
                view_more: "vedi di più",
                view_less: "vedi di meno",
                info_button: "Richiedi informazioni",
                contact: {
                    subject: "Richiesta informazioni {artworkName} di {artistName}",
                    body: {
                        greeting: "Ciao,",
                        body: "Sono interessato all'opera d'arte intitolata \"{artworkName}\" di {artistName}. Per favore forniscimi ulteriori informazioni.",
                        thank_you: "Grazie."
                    }
                }
            }
        },
        fairs: {
            future: "Fiere future",
            past: "Fiere passate"
        },
        about_us: {
            title: "Contattaci",
            info: "La Galleria Stefano Forni si tova nel cuore del centro storico di Bologna, in Piazza Cavour, 2. Fondata da Stefano Forni nel 1996 e oggi diretta da sua moglie Silvia Forni, la galleria è un punto di riferimento per l'arte contemporanea, presenta un ricco programma di mostre personali e collettive, con un'attenzione particolare a pittura, scultura, grafica e fotografia. <br><br> Rappresentiamo artisti di fama nazionale e internazionale, tra cui Luciano Ventrone, Armodio, Piero Guccione, Gianfranco Ferroni, Carlo Mattioli e molti altri. Ogni anno la Galleria partecipa a prestigiose fiere d'arte nazionali e internazionali, come Art Miami, Arte Fiera, Art Padova, Art Karlsruhe e Art Madrid. <br><br> Saremo lieti di accogliervi in galleria e farvi scoprire le nostre esposizioni che vi faranno immergere nella bellezza dell'arte moderna e contemporanea. Inoltre, seguiteci su Instagram e Facebook per rimanere sempre aggiornati sulle ultime novità e gli eventi in programma.",
            location: "Posizione",
            contact_us: "Contattaci",
            hours: "Orari",
            info_hours: "dal mercoledì al sabato <br> 10.00-12.30 e 16.00-19.30"
        },
        mailchimp: {
            title: "Iscriviti alla Newsletter",
            required: "campo obbligatorio",
            first_name: "Nome",
            last_name: "Cognome",
            email: "Indirizzo Email",
            privacy_title: "Accetto i termini e la privacy",
            privacy_text: "Acconsento al trattamento dei dati come descritto nella",
            subscribe: "Iscriviti",
        },
        footer: {
            home: "HOME",
            exhibitions: "MOSTRE",
            artists: "ARTISTI",
            news: "NOVITA",
            fairs: "FIERE",
            about_us: "CHI SIAMO",
        }
    }
}